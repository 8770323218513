.timersSection {
    position: relative;
    width: 100%;
    height: 100%;
    margin-right: auto;
    display: flex;
    flex-direction: column;

    >svg {
        width: 100%;
        height: 50%;
    }

    .mainTimer,
    .individualTimer {
        background: linear-gradient(rgba(255, 255, 255, .7), rgba(255, 255, 255, .9), rgba(255, 255, 255, .5));
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .individualTimersContainer {
        width: 100%;
        height: 50%;
        padding: 0 1.2%;
        display: flex;

        .timerIcon {
            height: 99%;
            padding: 4px 0 4px 4px;
        }

        .individualTimers {
            position: relative;
            width: 100%;
            height: 98%;
            margin: auto 0;
            overflow: hidden;

            >svg {
                position: absolute;
                width: 100%;
                height: 90%;
                transition-duration: .2s;
                background-color: black;
            }
        }
    }
}