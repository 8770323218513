@use "../../../../mixins/fonts";

.statRow {
    width: 100%;
    margin-bottom: 5px;
    display: flex;
    flex-wrap: wrap;
    font-family: fonts.$secondary-font;
    font-size: 19px;
    line-height: 19px;

    >h5 {
        font-size: 18px;
        width: 80px;
        margin: auto auto auto 0;
        font-weight: 600;
        white-space: nowrap;
    }

    >p,
    >svg {
        width: 70px;
        margin: auto 0;
        text-align: center;
    }

    >p {
        font-size: 17px;
    }

    >svg {
        height: 16px;
    }
}

@media screen and (max-width: 420px) {
    .statRow > h5 {
        width: 100%;
        font-size: 17px;
    }

    .statRow > p {
        text-align: right;
        margin-left: auto;
    }

    .statRow > p {
        font-size: 16px;
    }

    .statRow > svg {
        height: 15px;
        margin-right: -8%;
        margin-left: auto;
    }
}