@use "../../../../mixins/colors";

.modalContainer {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    > div {
        left: 0;
        top: 0;
    }

    .scoreInputContainer {
        width: 100%;
        max-width: 240px;
        padding: 20px;
        border-radius: 5px;

        p {
            width: calc(100% - 20px);
            display: flex;
            flex-wrap: wrap;
            font-size: 20px;
            line-height: 20px;
            font-weight: 300;
            letter-spacing: .5px;
        }

        .insertScoreContainer {
            padding: 3px;
            display: flex;
            flex-direction: column;
            overflow-x: hidden;
            display: flex;

            &>.scoreInput {
                height: 24px;
                margin: 10px 0 12px;
                background-color: rgba(0, 0, 0, .35);
                color: colors.$secondary-font-color;
                text-align: center;
                font-size: 20px;
                border-radius: 3px;
                border: 1px solid colors.$modal-border-color;
            }

            .insertScoreButton {
                width: 60%;
                margin: auto;
                background-color: colors.$button-bg;
                box-shadow: 0 0 3px 1px colors.$modal-box-shadow;
                border-radius: 2px;

                svg {
                    height: 22px;
                    margin-top: 3px;
                }
            }
        }
    }
}