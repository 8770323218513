@use "../../../../mixins/fonts";
@use "../../../../mixins/colors";

.loginForm {
    max-width: 360px;

    >p {
        width: 100%;
    }

    fieldset {
        display: flex;
        flex-wrap: wrap;

        >input,
        >button {
            height: 24px;
            border-radius: 3px;
            border: 1px solid colors.$modal-border-color;
            box-shadow: 0 0 2px 1px colors.$button-bg;
            color: colors.$secondary-font-color;
        }

        >input {
            width: 48%;
            margin: 1% auto;
            text-align: center;
            transition-duration: .1s;
            background-color: colors.$modal-fg;

            &[name="rePassword"] {
                width: 94%;
            }
        }

        >button {
            width: 98%;
            margin: 6px auto;
            background-color: rgba(0, 0, 0, 0.4);
        }
    }
}

.toggleFormSection {
    margin: 10px;
    color: colors.$secondary-font-color;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 1px;
    text-align: center;

    p {
        display: inline;
        margin-right: 4px;
    }

    button {
        background-color: transparent;
        font-family: fonts.$secondary-font;
        color: white;
        font-weight: inherit;
        font-size: 17px;
        letter-spacing: .5px;
        text-decoration: underline 1px;
        cursor: pointer;
    }
}

.loggedUserSection {
    width: 60vw;
    height: 110px;
    min-width: 180px;
    max-width: 280px;
    margin: 10px 0 12px;
    display: flex;
    gap: 10px;

    .userAvatarContainer {
        position: relative;
        width: 50%;
        height: 100%;
        display: inline-flex;

        p {
            position: absolute;
            top: -4px;
            left: -4px;
            max-width: 100%;
            padding: 4px 8px;
            text-shadow: 0 1px 2px black;
            font-style: italic;
            font-size: 14px;
            border-radius: 4px;
            background-color: rgba(0, 0, 0, .65);
            overflow: hidden;
        }
    }

    .loggedButtonsContainer {
        position: relative;
        width: 50%;
        margin-left: 0px;

        button {
            position: absolute;
            right: 0;
            width: 100%;
            min-height: 24px;
            padding: 4px 0;
            border-radius: 2px;
            background-color: rgba(0, 0, 0, 0.3);
            box-shadow: 0 0 2px 1px colors.$button-bg;
            color: colors.$secondary-font-color;
        }

        button:nth-child(1) {
            top: 10px;
        }
        button:nth-child(2) {
            bottom: 10px;
        }
    }
}