@use "../../../../../mixins/colors";

.checkbox {
    display: flex;
    margin-bottom: 12px;
    gap: 30px;

    input {
        width: 14px;
        height: 14px;
        padding: 0;
        margin: auto 0 auto auto;
        border: 1px solid colors.$modal-border-color;
    }
}