@use "../../../mixins/main-components-style" as styles-mixin;
@use "../../../mixins/colors";

footer {
    @include styles-mixin.main-components-style;

    height: calc(72px + styles-mixin.$additional-height);
    width: 100%;
    position: relative;

    .startButton,
    .pauseButton {
        position: relative;
        height: 100%;
        border-radius: 3px;
        transition-duration: .1s;
        box-shadow: inset 0 0 3px 0px rgba(0, 0, 0, 1);
        overflow: hidden;
        transform: scale(1, 1);

        .buttonBg {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            transition-duration: .4s;
            z-index: -1;
        }
    }

    .startButton:after,
    .pauseButton:after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        box-shadow: inset 0 0 3px 0px rgba(0, 0, 0, .8);
        z-index: 2;
        transition-duration: .1s;
    }

    .startButton:active:after,
    .pauseButton:active:after {
        box-shadow: inset 0 0 4px 1px rgba(0, 0, 0, .8);
    }

    .pauseButton {
        position: absolute;
        right: 0;
        top: 0;
        width: 0%;
    }

    svg {
        width: 100%;
        height: 100%;
        display: block;
        object-fit: contain;
        transition-duration: .3s;

        use,
        text {
            font-family: "Pacifico", cursive;
            font-size: 36px;
            font-weight: 100;
            transition-duration: .3s;
            user-select: none;
        }

        use {
            transition-duration: .1s;
        }
    }
}

@media screen and (max-width: 500px) {
    footer svg {
        height: 78%;
    }
}