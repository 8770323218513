@use "../../../mixins/colors";

.modal {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    padding: 10px;
    display: flex;
    background-color: colors.$button-bg;
    transition-delay: 0s;

    .modalInternal {
        margin: auto;
        position: relative;
        display: flex;
        transform: scale(0, 0);
        transition-duration: .1s;
        background-color: colors.$modal-bg;
        border-radius: 6px;
        border: 1px solid colors.$modal-border-color;
        box-shadow: 0 0 0 2px colors.$modal-bg;
        overflow: hidden;

        .closeButton {
            position: absolute;
            right: 13px;
            top: 13px;
            width: 24px;
            height: 24px;
            padding: 3px;
            background-color: colors.$button-bg;
            box-shadow: 0 0 0 2px colors.$modal-fg;
            border-radius: 3px;
            border: 1px solid colors.$modal-border-color;
            z-index: 2;

            &>svg {
                width: 100%;
                height: 100%;
            }
        }
    }
}