@font-face {
	font-family: "Pacifico";
	src: url("./assets/fonts/Pacifico-Regular.woff");
	src: url("./assets/fonts/Pacifico-Regular.woff2");
}

@font-face {
	font-family: "Alumni Sans";
	src: url("./assets/fonts/Alumni-Sans.woff");
	src: url("./assets/fonts/Alumni-Sans.woff2");
}

* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
	text-decoration: none;
	outline: none;
	border: none;
}

*::-webkit-scrollbar {
	width: 12px;
	height: 12px;
}

*::-webkit-scrollbar-track {
	background-color: transparent;
}

*::-webkit-scrollbar-thumb {
	border: 3px solid rgba(0, 0, 0, .8);
	background-color: rgba(255, 255, 255, .5);
	border-radius: 6px;
}

body {
	height: 100vh;
	margin: auto;
	max-width: 1180px;
	background-color: black;
	color: white;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type=number] {
	-moz-appearance: textfield;
}