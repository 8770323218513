@use "../mixins/colors";
@use "../mixins/fonts";

.errorBoundary {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h1 {
        margin-bottom: 30px;
        font-family: fonts.$primary-font;
        font-size: 38px;
        font-weight: 200;
    }

    button {
        padding: 8px 10px;
        background-color: colors.$modal-bg;
        font-family: fonts.$secondary-font;
        font-size: 22px;
        color: white;
        border-radius: 3px;
        border: 1px solid colors.$modal-border-color;
    }
}