@use "../../../mixins/main-components-style" as styles-mixin;
@use "../../../mixins/fonts";

header {
    @include styles-mixin.main-components-style;

    height: calc(47px + styles-mixin.$additional-height);
    display: flex;

    grid-template-rows: calc(47px + styles-mixin.$additional-height);
    justify-items: center;

    >svg {
        flex-grow: 1;
        height: 100%;
        padding: 0 9px;
        object-fit: contain;

        use,
        text {
            font-family: fonts.$primary-font;
            font-size: 32px;
            line-height: 32px;
            font-weight: 100;
        }
    }

    .timersWrapper,
    .buttonsContainer {
        width: 12%;
        height: 100%;
        min-width: 90px;
        max-width: 108px;
    }

    .buttonsContainer {
        display: block;

        .buttonWrapper {
            width: 50%;
            height: 100%;
            display: inline-flex;

            >button {
                position: relative;
                width: 100%;
                height: 100%;
                display: flex;
                background-color: transparent;

                &::after {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    box-shadow: inset 0 0 2px 1px rgba(255, 255, 255, .2);
                    background-image: linear-gradient(
                        to bottom,
                        rgba(255, 255, 255, .2),
                        rgba(0, 0, 0, .3) 55%,
                        rgba(0, 0, 0, .4)
                    );
                }

                svg {
                    width: 45%;
                    height: auto;
                    margin: auto;
                }
            }
        }
    }
}