@use "../../../../mixins/main-components-style" as main-styles;

$thin: 400;
$bold: 500;

.scoreColumn {
    height: 100%;
    min-width: 86px;
    flex-grow: 1;
    overflow-y: auto;
    display: block;
    box-shadow: inset 0 0 2px 0px rgba(255, 255, 255, .5);
    border: 1px solid rgba(255, 255, 255, .1);

    .columnInternal {
        position: relative;
        height: 100%;
        display: grid;
        grid-template-rows: auto 1fr auto;

        .columnHeader,
        .columnFooter {
            width: 100%;
            height: calc(36px + (main-styles.$additional-height / 2));
            padding: 0 9px;
            display: flex;
            font-weight: $bold;

            >div {
                margin: auto 0;
            }
        }

        .columnHeader {
            border-bottom: 1px solid rgba(255, 255, 255, .4);
            input {
                font-weight: $bold;
            }

            >button {
                display: inline;
                margin: auto;
                font-size: 18px;
                line-height: 18px;
            }
        }

        .columnBody {
            padding: 0 9px;
            overflow-y: auto;
            user-select: none;

            input {
                font-weight: $thin;
                text-align: right;
            }
        }

        .columnFooter {
            border-top: 1px solid rgba(255, 255, 255, .4);

            >span {
                margin: auto 0 auto auto;
                font-size: 20px;
                line-height: 20px;
                text-align: right;
            }
        }

        input {
            letter-spacing: 1px;
        }
    }
}