@use "../../../mixins/fonts";
@use "../../../mixins/colors";

$summary-title-font-weight: 400;

.endGameModal {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    >svg {
        width: auto;
    }

    .scoreDetails {
        width: 92vw;
        max-width: 500px;
        max-height: 92vh;
        padding: 10px;
        display: flex;
        flex-direction: column;
        box-shadow: inset 0 0 2px 0px black;
        border-radius: 6px;
        text-shadow: 1px 1px 1px rgba(0, 0, 0, .6);

        .topContainer {
            width: 100%;
            padding-bottom: 6px;
            display: flex;

            .topButton {
                width: 50px;
                height: 50px;
                margin: auto 20px;
                background-color: rgba(0, 0, 0, 0.3);
                font-family: fonts.$secondary-font;
                color: rgba(255, 255, 255, .75);
                font-size: 18px;
                border-radius: 3px;
                border: 1px solid rgba(255, 255, 255, 0.5);
                box-shadow: 0 0 0 2px #292a2c;
            }

            >svg {
                width: auto;
                height: 82%;
                max-height: 170px;
                padding: .5vh 0;
                margin: auto;
                font-family: fonts.$primary-font;
                font-size: 65px;
                line-height: 50px;
                font-weight: normal;
            }
        }

        h3 {
            font-style: italic;
            text-shadow: 0 0 4px red;
        }

        h4 {
            font-family: fonts.$secondary-font;
            font-weight: 500;
        }

        .scoreDetailsInternal {
            padding: 1%;
            overflow: auto;

            .scoreDetailsTop {
                width: 100%;
                display: flex;

                .winnerName {
                    margin-bottom: 15px;
                    font-size: 25px;
                    line-height: 20px;
                    text-align: center;
                }

                p {
                    color: rgba(255, 255, 255, .75);
                }

                >div {
                    width: 50%;

                    &:nth-child(1) {
                        border-right: 1px solid white;

                        h3 {
                            padding-right: 2px;
                        }
                    }

                    &:nth-child(2) {
                        padding-left: 10px;
                    }
                }
            }
        }

        .playerStats {
            display: flex;

            >div {
                flex-grow: 1;
                text-align: center;

                >h3 {
                    font-size: 26px;
                }

                >h4 {
                    font-size: 22px;
                    line-height: 22px;
                    margin-bottom: 4px;
                }

                >P {
                    font-family: fonts.$secondary-font;
                    font-size: 18px;

                    >span {
                        font-size: 16px;
                    }
                }

                >svg {
                    width: 100%;
                    height: 19px;
                    margin: 0 auto;
                }
            }
        }

        .ranking {
            width: 100%;
            min-height: 137px;
            margin: 30px auto 0;
            overflow: hidden;
            box-shadow: inset 0 0 8px 4px colors.$modal-bg;
        }
    }
}

@media screen and (max-width: 394px) {
    .endGameModal .scoreDetails .topContainer .topButton {
        width: 40px;
        height: 40px;
        margin: auto 15px;
    }
}