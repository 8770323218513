@use "../../../mixins/main-components-style" as styles-mixin;

.main {
    @include styles-mixin.main-components-style;

    max-height: calc(97.1374045802vh - 119px);
    position: relative;
    flex-grow: 1;
    flex-direction: column;

    .scoreColumnsContainer {
        width: 100%;
        height: 100%;
        display: flex;
        overflow-x: auto;
    }
}