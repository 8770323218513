@use "../../../../../mixins/fonts";

.scoreField {
    position: relative;
    width: 100%;
    height: 22px;
    margin: 9px 0;
    display: flex;
    z-index: 1;
    user-select: none;

    >input {
        width: 96%;
        height: 100%;
        background-color: transparent;
        margin-left: auto;
        color: rgba(255, 255, 255, .8);
        font-family: fonts.$secondary-font;
        font-size: 19px;
        transition-duration: .2s;
        box-shadow: inset 0 0 0 0 #121212;
        user-select: text;

        &[disabled='false']:active {
            background-color: #222222;
            box-shadow: inset 0 0 3px 1px #121212;
        }
    }

    >button {
        position: absolute;
        left: 0;
        top: 0;
        width: 44px;
        height: 24px;
        background-color: transparent;

        >svg {
            pointer-events: none;
        }
    }

    .editToggle {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }
}