@use "../../../../mixins/colors";

$box-shadow: 0 0 0 1px colors.$message-container-box-shadow;

.shadowWrapper {
    position: absolute;
    max-width: calc(100vw - 16px);
    max-height: calc(100% - 2px);
    display: inline-flex;
    transition-duration: .2s;
    transition-timing-function: ease-out;
    box-shadow: 0 0 3px 4px rgba(50, 50, 50, 1);
    border-radius: 4px;
    opacity: 0;

    .modalInternalSection {
        position: relative;
        padding: 10px 5px 10px 10px;
        background-color: rgba(0, 0, 0, .2);
        animation-name: flash;
        animation-timing-function: linear;
        animation-iteration-count: 1;
        animation-duration: .5s;
        border-radius: 4px;
        box-shadow: $box-shadow;

        .closeButton,
        .hideButton {
            position: absolute;
            right: -9px;
            width: 16px;
            height: 16px;
            background-color: rgba(50, 50, 50, .9);
            border-radius: 50%;
            box-shadow: 0 0 3px 4px rgba(50, 50, 50, 1);

            &>svg {
                width: 100%;
                height: 100%;
                border-radius: 50%;
                box-shadow: $box-shadow;

                &>path {
                    fill: colors.$secondary-font-color;
                }
            }
        }

        .closeButton {
            top: -9px;
        }

        .hideButton {
            bottom: -9px;
        }

        .messageList {
            max-height: 100%;
            padding: 0 10px;
            overflow-y: auto;
            overflow-x: hidden;

            .error {
                font-size: 18px;
                text-shadow: 0 0 3px black;
                letter-spacing: .5px;
            }
        }
    }
}

@keyframes flash {
    0%{background-color: rgba(0, 0, 0, .2)}
    25%{background-color: rgba(0, 0, 0, 0)}
    50%{background-color: rgba(0, 0, 0, .2)}
    75%{background-color: rgba(0, 0, 0, 0)}
    100%{background-color: rgba(0, 0, 0, .2)}
}