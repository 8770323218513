.settingsSection {
    padding: 60px 30px 10px;

    h3 {
        position: absolute;
        top: 10px;
        left: 16px;
        font-size: 28px;
    }

    &>div {
        font-size: 17px;
        letter-spacing: .5px;
    }
}