@use "../../../../../mixins/colors";

.numberInput {
    display: flex;
    margin-bottom: 12px;
    letter-spacing: .5px;
    gap: 30px;

    input {
        padding: 1px 0 0 4px;
        margin-left: auto;
        width: 8vw;
        color: colors.$secondary-font-color;
        background-color: colors.$button-bg;
        min-width: 45px;
        max-width: 65px;
        border-radius: 3px;
        border: 1px solid colors.$modal-border-color;
        box-shadow: 0 0 0 2px colors.$modal-fg;
    }
}